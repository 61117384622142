<template>
    <CSidebar fixed :minimize="minimize" :show="show" @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
        <div class="row m-0 mb-3">
            <div class="col-3 pr-1">
                <center>
                    <img src="img/logo-kementan.png" style="width: 100%;" alt="" />
                </center>
            </div>
            <div class="col-9 pl-0">
                <b>
                    Kementrian Pertanian <br />
                    Republik Indonesia
                </b>
            </div>
        </div>
        <center></center>
        <CRenderFunction flat :contentToRender="computedSidebar" />
        <CSidebarMinimizer class="d-md-down-none" @click.native="$store.commit('set', ['sidebarMinimize', !minimize])" />
    </CSidebar>
</template>
<script>
import allItems from "./_nav";
export default {
  name: "TheSidebar",
  data() {
    return {
      showSidebar: true,
      session_role_id: JSON.parse(this.session).roles_id,
      session_data: JSON.parse(this.session),
      permission_data: undefined,
    };
  },
  computed: {
    // currentItems() {
    //   //sidebar items are not shown until role is known
    //   if (this.session_role_id === "unknown") {
    //     return [];
    //   }
    //   return allItems.filter((item) => {
    //     var child = item.items;
    //     var show_child = [];
    //     if (child) {
    //       child.filter((sub_child) => {
    //         var child_role = sub_child.roles_id;
    //         if (!child_role || child_role.includes(this.session_role_id)) {
    //           show_child.push(sub_child);
    //         }
    //       });
    //     }
    //     item.items = show_child;
    //     return !item.roles_id || item.roles_id.includes(this.session_role_id);
    //   });
    // },
    userPermissions() {
      return this.session_data &&
        this.session_data.roles &&
        this.session_data.roles.role_permission
        ? this.session_data.roles.role_permission.map((p) => p.permission_name)
        : [];
    },
    currentItems() {
      return allItems.filter((item) => {
        const hasMainPermission =
          this.userPermissions &&
          this.userPermissions.includes(item.is_permission);


        if (item.items && item.items.length) {
          item.items = item.items.filter((subItem) => {
            // Cek permission untuk setiap sub-item
            return this.userPermissions.includes(subItem.is_permission);
          });
        }

        return hasMainPermission || (item.items && item.items.length > 0);
      });
    },
    computedSidebar() {
      return [
        {
          _name: "CSidebarNav",
          _children: this.currentItems,
        },
      ];
    },
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
  created() {
    console.log(this.userPermissions);

            console.log(this.session_data);
        }
    };
</script>
<template>
    <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
        <template #toggler>
            <CHeaderNavLink>
                <div class="c-avatar">
                    <img src="img/logo-kementan.png" class="c-avatar-img" />
                </div>
            </CHeaderNavLink>
        </template>
        <CDropdownHeader tag="div" class="text-center" color="light">
            <strong>{{session_nama}}</strong>
        </CDropdownHeader>
        <CDropdownItem :to="{'name': 'ProfileAdmin'}"> <CIcon name="cil-user" /> Profile </CDropdownItem>
        <CDropdownItem :to="{'name': 'Konfigurasi'}"> <CIcon name="cil-settings" /> Settings </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem :to="{'name': 'LogoutAdmin'}"> <CIcon name="cil-lock-locked" /> Logout </CDropdownItem>
    </CDropdown>
</template>
<script>
    export default {
        name: "TheHeaderDropdownAccnt",
        data() {
            return {
                itemsCount: 42,
                session_nama: this.session_nama,
            };
        },
    };
</script>
<style scoped>
    .c-icon {
        margin-right: 0.3rem;
    }
</style>
<template>
  <div class="c-app">
    <TheSidebar/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <footer class="c-footer bg-white" style="height: 100% !important;">
        <div class="container p-0">
          <div class="row py-2">
            <div class="col-md-6">
              <div><a href="#" target="_blank">PPVTPP</a><span class="ml-1">&copy; {{new Date().getFullYear()}} Kementrian Pertanian.</span></div>
            </div>
            <div class="col-md-6">
              <div class="mfs-auto text-md-right"><span class="mr-1">Powered by</span><a href="https://perizinan.pertanian.go.id/" target="_blank">Pusat Perlindungan Varietas Tanaman dan Perizinan Pertanian</a></div>
            </div>
          </div>
        </div>
        
      </footer>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'


export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,

  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

export default [
  {
    _name: "CSidebarNavItem",
    name: "Dashboard",
    to: "/dashboard",
    is_permission: "can_view_dashboard",
  },
  {
    _name: "CSidebarNavItem",
    name: "Dashboard Hortikultura",
    to: "/graph/dashboard-hortikultura",
    // roles_id: [10, 11, 8, 9, 1, 2, 35, 7, 3, 4, 5, 6],
    is_permission: "can_view_dashboard_horti",
  },
  {
    _name: "CSidebarNavItem",
    name: "Dashboard Estimasi Permohonan",
    to: "/laporan/dashboard-estimasi-permohonan",
    // roles_id: [1],
    is_permission: "can_view_dashboard_estimasi_permohonan",
  },
  {
    _name: "CSidebarNavDropdown",
    name: "Semua Permohonan",
    route: "/permohonan",
    items: [
      {
        name: "Benih Tanaman Hortikultura",
        to: "/permohonan/list-permohonan/Hortikultura",
        // roles_id: [10, 11, 8, 9, 1, 2, 35, 7, 3, 4, 5, 6],
        is_permission: "can_view_list_permohonan_horti",
      },
      {
        name: "Benih Tanaman Tanaman Pangan",
        to: "/permohonan/list-permohonan/Tanaman Pangan",
        // roles_id: [16, 17, 13, 15, 14, 12, 1, 2, 35, 7, 3, 4, 5, 6],
        is_permission: "can_view_list_permohonan_tp",
      },
      {
        name: "Benih Tanaman Perkebunan",
        to: "/permohonan/list-permohonan/Perkebunan",
        // roles_id: [
        //   41, 40, 39, 23, 22, 21, 20, 19, 18, 42, 1, 2, 35, 7, 3, 4, 5, 6, 60,
        //   61,
        // ],
        is_permission: "can_view_list_permohonan_bun",
      },
      {
        name: "Benih Tanaman SDG",
        to: "/permohonan/list-permohonan/Sumber Daya Genetik",
        // roles_id: [26, 24, 25, 28, 29, 30, 31, 27, 1, 2, 35, 7, 3, 4, 5, 6],
        is_permission: "can_view_list_permohonan_sdg",
      },
      {
        name: "Benih Tanaman Pakan Ternak",
        to: "/permohonan/list-permohonan/Benih Tanaman Pakan Ternak",
        // roles_id: [26, 24, 25, 28, 29, 30, 31, 27, 1, 2, 35, 7, 3, 4, 5, 6],
        // roles_id: [2, 3, 4, 5, 6, 62, 63, 64, 67, 68, 1, 35, 24],
        is_permission: "can_view_list_permohonan_hpt",
      },
    ],
  },
  {
    _name: "CSidebarNavTitle",
    _children: ["Laporan"],
    is_permission: "show_label_report",
  },
  {
    _name: "CSidebarNavDropdown",
    name: "Laporan Data Benih",
    route: "/laporan",
    items: [
      {
        name: "Pemasukan Data Benih",
        to: "/laporan/laporan-pemasukan-data-benih",
        is_permission: "can_view_report_pemasukan_benih",
      },
      {
        name: "Pengeluaran Data Benih",
        to: "/laporan/laporan-pengeluaran-data-benih",
        is_permission: "can_view_report_pengeluaran_benih",
      },
    ],
  },
  {
    _name: "CSidebarNavDropdown",
    name: "Laporan SIP",
    route: "/laporan",
    items: [
      {
        name: "Pemasukan SIP",
        to: "/laporan/laporan-sip-pemasukan",
        is_permission: "can_view_report_sip_pemasukan",
      },
      {
        name: "Pengeluaran SIP",
        to: "/laporan/laporan-sip-pengeluaran",
        is_permission: "can_view_report_sip_pengeluaran",
      },
    ],
  },
  {
    _name: "CSidebarNavItem",
    name: "Laporan Realisasi",
    to: "/laporan/laporan-realisasi",
    is_permission: "can_view_report_realisasi",
  },
  {
    _name: "CSidebarNavItem",
    name: "Log Aktifitas",
    to: "/laporan/log-aktifitas",
    is_permission: "can_view_report_log_aktifitas",
  },
  {
    _name: "CSidebarNavItem",
    name: "Laporan Kinerja",
    to: "/laporan/laporan-kinerja",
    is_permission: "can_view_report_kinerja",
  },
  {
    _name: "CSidebarNavItem",
    name: "Daftar Perusahaan",
    to: "/master/perusahaan",
    // roles_id: [1, 3, 4, 5, 6, 8],
    is_permission: "can_view_master_perusahaan",
  },
  {
    _name: "CSidebarNavTitle",
    _children: ["Data Master"],
    // roles_id: [1, 3, 4, 5, 6, 8],
    is_permission: "show_label_data_master",
  },
  {
    _name: "CSidebarNavDropdown",
    name: "Akun",
    route: "/akun",
    // roles_id: [1, 3, 4, 5, 6, 8],
    items: [
      {
        name: "Role",
        to: "/master/role",
        is_permission: "can_view_master_role",
      },
      {
        name: "User Admin",
        to: "/master/user/admin",
        is_permission: "can_view_master_user_admin",
      },
      {
        name: "User Pemohon",
        to: "/master/user/pemohon",
        is_permission: "can_view_master_user_pemohon",
      },
    ],
  },
  {
    _name: "CSidebarNavDropdown",
    name: "Master Data",
    route: "/master",
    // roles_id: [1, 3, 4, 5, 6, 8],
    items: [
      {
        name: "Aropt",
        to: "/master/aropt",
        is_permission: "can_view_master_aropt",
      },
      {
        name: "Bentuk Benih",
        to: "/master/bentuk-benih",
        is_permission: "can_view_master_bentuk_benih",
      },
      {
        name: "Dokumen Persyaratan",
        to: "/master/dokumen-persyaratan",
        is_permission: "can_view_master_dok_persyaratan",
      },
      {
        name: "FAQ",
        to: "/master/faq",
        is_permission: "can_view_master_faq",
      },
      {
        name: "File Download",
        to: "/master/file-download",
        is_permission: "can_view_master_file_download",
      },
      {
        name: "Jenis Tanaman",
        to: "/master/jenis-tanaman",
        is_permission: "can_view_master_jenis_tanaman",
      },
      {
        name: "Kelompok Jenis Tanaman",
        to: "/master/kelompok-jenis-tanaman",
        is_permission: "can_view_master_kelompok_jenis_tanaman",
      },
      {
        name: "Module",
        to: "/master/module",
        is_permission: "can_view_master_module",
      },
      {
        name: "Negara",
        to: "/master/negara",
        is_permission: "can_view_master_negara",
      },
      {
        name: "Pelabuhan",
        to: "/master/pelabuhan",
        is_permission: "can_view_master_pelabuhan",
      },
      {
        name: "Satuan",
        to: "/master/satuan",
        is_permission: "can_view_master_satuan",
      },
      {
        name: "Sub Kelompok Jenis Tanaman",
        to: "/master/sub-kelompok-jenis-tanaman",
        is_permission: "can_view_master_sub_kelompok_jenis_tanaman",
      },
      {
        name: "Tipe FAQ",
        to: "/master/tipe-faq",
        is_permission: "can_view_master_tipe_faq",
      },
      {
        name: "Tipe Pemohon",
        to: "/master/tipe-pemohon",
        is_permission: "can_view_master_tipe_pemohon",
      },
      {
        name: "Tujuan Penggunaan",
        to: "/master/tujuan-benih",
        is_permission: "can_view_master_tujuan_benih",
      },
      {
        name: "Jadwal Libur Pelayanan",
        to: "/master/jadwal-libur-pelayanan",
        is_permission: "can_view_master_jadwal_libur",
      },
    ],
  },
];